
/**
 * @name: coachReconcile
 * @author: Gzm
 * @date: 2023-05-30 10:27
 * @description：财务结算-教练佣金对账
 * @update: 2023-05-30 10:27
 */
import {Component, Vue} from "vue-property-decorator";
import {Message} from "element-ui";
import {
  coachSettlementAuditApi,
  coachSettlementBatchGrantApi,
  queryCoachSettlementByPageApi
} from "@/apis/finance/coachReconcile";
import {ICoachByPage} from "@/apis/finance/coachReconcile/types";
import CoachReconcileUltimo from "@/views/finance/components/coachReconcileUltimo.vue";
import CoachReconcileLook from "@/views/finance/components/coachReconcileLook.vue";

@Component({
  components: {CoachReconcileLook, CoachReconcileUltimo}
})
export default class UserIndex extends Vue {

  queryForm: any = {
    page: 1,
    limit: 10,
    //1=普通用户 2=达人 3=教练 4=渠道方
    userTypes:3
  }
  total: number = 0
  // 弹窗开关
  dialogVisible = false
  dialogVisibleLook = false

  tableData: ICoachByPage[] = []

  ultimoData:any = {}
  lookData:any = {}

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: any = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: "",
        type: "selection",
        width: 50,
        align: "center",
        selectable: this.rowSelectble,
        viewHide: true
      },
      {
        label: "ID",
        prop: "id",
        align: 'center',
        width: 200,
      },
      {
        label: "教练名称",
        prop: "userName",
        align: 'center',
      },
      {
        label: "上月售出视频课程",
        prop: "lastMonthCourseCount",
        align: 'center',
      },
      {
        label: "手机号",
        prop: "phone",
        align: 'center',
      },
      {
        label: "上月售出课程",
        prop: "lastMonthSoldNumber",
        align: 'center',
      },
      {
        label: "上月课程消费",
        prop: "lastMonthConsumeTotal",
        align: 'center',
      },
      {
        label: "上月客户消费卡数",
        prop: "lastMonthCardNumber",
        align: 'center',
      },
      {
        label: "上月累计佣金",
        prop: "lastMonthCommission",
        align: 'center',
      },
      {
        label: "状态",
        prop: "settlementStatus",
        align: 'center',
        value: 2,
        type: "switch",//状态:1=有效 2=无效
        dicData: [{label: '待申请结算佣金', value: 1}, {label: '待审核', value: 2}, {
          label: '审核不通过',
          value: 3
        }, {label: '待发放上月佣金', value: 4}, {label: '已发放上月佣金', value: 5}],
      },
      {
        label: "发放时间",
        prop: "grantTime",
        align: 'center',
      },
      {
        label: "操作",
        prop: "operate",
        align: 'center',
        slot: true
      },
    ]
  }
  selectRow: any = []

  get selectIds() {
    return this.selectRow.map((item: any) => item.id)
  }

  handleBatch() {
    if (this.selectIds.length == 0) {
      return this.$message.error("请选择数据")
    }
    this.handleSettle(this.selectIds)
  }

  handleSettle(ids: string[]) {
    if (!ids || ids.length == 0) {
      return
    }
    this.$confirm('是否批量发放上月佣金?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      let idList = ids.join(',')
      coachSettlementBatchGrantApi(idList).then(e => {
        this.$message.success("操作成功!")
        this.getList()
      })
    }).catch(() => {

    });
  }

  rowSelectble(row: any, index: number) {
    if (row.settlementStatus == 4) {
      return true
    }
    return false
  }

  /**
   * 获取数据
   */
  getList() {
    queryCoachSettlementByPageApi(this.queryForm).then(e => {
      this.tableData = e.list;
      this.total = e.total
    })
  }


  /**
   * 打开弹窗
   */
  async openAdd(row:any) {
    this.ultimoData = row
    this.dialogVisible = true
  }

  /**
   * 打开弹窗-查看佣金
   */
  async openLook(row:any) {
    this.lookData = row
    this.dialogVisibleLook = true
  }

  /**
   * 删除
   * @param id
   */
  handleRemove(id: string) {
    this.$confirm('佣金是否审核通过?', '审核', {
      distinguishCancelAndClose: true,
      confirmButtonText: '通过',
      cancelButtonText: '不通过',
      type: 'warning'
    }).then(() => {
      coachSettlementAuditApi({id,status:1}).then(e => {
        if (e) {
          Message.success("审核成功!")
          this.getList()
        }
      })
    }).catch((action ) => {
      if (action == "cancel"){
        coachSettlementAuditApi({id,status:2}).then(e => {
          if (e) {
            Message.success("审核成功!")
            this.getList()
          }
        })
      }

    });
  }

  handleUltimo(e:any){
    if (e){
      coachSettlementBatchGrantApi(this.ultimoData.id).then(e => {
        this.$message.success("操作成功!")
        this.getList()
        this.dialogVisible = false
      })
    }else {
      this.dialogVisible = e
    }
  }

  handleLook(e:any){
    this.dialogVisibleLook = e
  }


  created() {
    this.getList();
  }
}
